(function() {
    'use strict';

    angular
        .module('app.layout')
        .directive('single', single);

    /* @ngInject */
    function single() {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            bindToController: true,
            templateUrl: 'single.tpl',
            // templateUrl: function(elem, attr) {
            //     return 'single-' + attr.type + '.tpl';
            // },
            controller: singleController,
            controllerAs: 'vm',
            link: link,
            restrict: 'E',
            scope: {
                data: '<'
            }
        };
        return directive;
        /////////////////
        
        function link(scope, element, attrs) {
            // console.log([scope, element, attrs]);
        }

    }

    /* @ngInject */
    function singleController() {
    }

})();