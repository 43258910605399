(function() {
    'use strict';

    angular
        .module('app.core')
        .provider('$api', $api);

    function $api() {
        /* jshint validthis:true */
        this.conf = {};

        this.configure = function(cfg) {
            this.conf = cfg;
            console.log(this.conf.routes);
            angular.forEach(this.conf.routes, function(route, uri) {
                console.log([uri, route]);
            });
        };

        /* @ngInject */
        this.$get = function($rootScope, $q, $interval, $http) {

            var Lnk = (function() { function Lnk(name, parent) { parent = parent || { url: '' }; this.url = parent.url + '/' + name; }
                Lnk.prototype.add = function(name, parent) { if(!this[name]) this[name] = new Lnk(name, parent); return this[name]; }; return Lnk;
            })();

            var api = new Lnk('api');

            return api; // die

            var Requestor = (function() { function Requestor(path, methods) {
                var self = this; self = { path: path, methods: methods };
                if(methods.indexOf('c') > -1) self.create = function(config) { return apiReq(path, 'POST', config); };
                if(methods.indexOf('r') > -1) self.read = function(config) { return apiReq(path, 'GET', config); };
                if(methods.indexOf('u') > -1) self.update = function(config) { return apiReq(path, 'PUT', config); };
                if(methods.indexOf('d') > -1) self.delete = function(config) { return apiReq(path, 'DELETE', config); };
                return self;
            } return Requestor; })();

            angular.forEach(this.conf, function(methods, path) {
                var pathParts = path.replace(/^\/api\//, '').split('/'), currentLevel = api;
                angular.forEach(pathParts, function(part, idx) { if(!currentLevel[part]) {
                    if(idx + 1 == pathParts.length) { currentLevel[part] = new Requestor(path, methods); }
                    else { currentLevel.add(part, currentLevel); }
                } currentLevel = currentLevel[part]; });
            });

            // console.log(api);
            // console.log(api.app);
            // console.log(api.app.init);
            // console.log(api.test);
            // console.log(api.test.it);
            // console.log(api.tst2);
            // console.log(api.tst2.at);
            // api.app.init.read().then(function(res) { console.log(res); });

            return api;
            ///////////

            function apiReq(path, method, config) {

                console.log(['apiReq', path, method, config]);

                var deferred = $q.defer(),
                    requested = false,
                    completed = $interval(function() {

                        if((path === '/api/app/init' || $rootScope.asid) && !requested) {
                            requested = true;
                            config = typeof config !== 'undefined' ? config : {};
                            config.url = path;
                            config.method = method;
                            config.headers = typeof config.headers !== 'undefined' ? config.headers : {};
                            config.headers.asid = $rootScope.asid;
                            config.headers.nph = 1;
                            console.log(['config', config]);
                            $http(config).then(
                                function(response) {
                                    console.log(['resolve', response]);
                                    deferred.resolve(response);
                                },
                                function(response) {
                                    console.log(['reject', response]);
                                    deferred.resolve(response);
                                }
                            );
                        }

                    }, 250, 20);

                completed.then(function() { deferred.reject('timeouted'); });

                return deferred.promise;

            }

        };

    }

})();
