(function() {
    'use strict';

    angular
        .module('app.layout')
        .controller('routeCtrl', routeCtrl);

    /* @ngInject */
    function routeCtrl(
        $scope, $rootScope, $location, $routeParams, $window,
        $animate, $timeout, $sce, config, logger, $http, $api, $data, json
    ) {

        $scope.data = json.data;
        if($scope.data.stats && $scope.data.stats.page_title) {
            // console.log(['page_title', $scope.data.stats.page_title]);
            document.title = $scope.data.stats.page_title;
            // $rootScope.title = $scope.data.stats.page_title;
        }
        $scope.selectTemplate = function() {
            if(
                $scope.data.stats.is_home
            ) return 'home';
            if(
                $scope.data.stats.is_single &&
                $scope.data.stats.post_type	== 'animal'
            ) return 'single_animal';
            if(
                $scope.data.stats.is_single
            ) return 'single';
            if(
                $scope.data.stats.is_archive &&
                $scope.data.stats.is_post_type_archive &&
                $scope.data.stats.post_type	== 'animal'
            ) return 'archive_animal';
            if(
                $scope.data.stats.is_archive &&
                $scope.data.stats.is_tax
            ) return 'tax_archive';
            if(
                $scope.data.stats.is_archive &&
                $scope.data.stats.is_post_type_archive
            ) return 'pt_archive';
            if(
                $scope.data.stats.is_archive
            ) return 'archive';
            if(
                $scope.data.stats.is_404
            ) return '404';
            return;
        };
/*
        $scope.menu = [
            {"ID":816,"order":1,"parent":0,"title":"Home","url":"http://awp.dev.adler.limeira.eu/","attr":"","target":"","classes":"","xfn":"","description":"","object_id":2,"object":"page","type":"post_type","type_label":"Page","children":[]},
            {"ID":817,"order":2,"parent":0,"title":"Hello World","url":"http://awp.dev.adler.limeira.eu/hello-world/","attr":"","target":"","classes":"","xfn":"","description":"","object_id":1,"object":"post","type":"post_type","type_label":"Post","children":[]},
            {"ID":818,"order":3,"parent":0,"title":"Uncategorized","url":"http://awp.dev.adler.limeira.eu/category/uncategorized/","attr":"","target":"","classes":"","xfn":"","description":"","object_id":1,"object":"category","type":"taxonomy","type_label":"Category","children":[]},
            {"ID":829,"order":4,"parent":0,"title":"Animals","url":"/animals/","attr":"","target":"","classes":"","xfn":"","description":"","object_id":829,"object":"custom","type":"custom","type_label":"Custom Link","children":[
                {"ID":819,"order":5,"parent":829,"title":"blue","url":"http://awp.dev.adler.limeira.eu/animals/blue/","attr":"","target":"","classes":"","xfn":"","description":"","object_id":5,"object":"animal_type","type":"taxonomy","type_label":"Animal type","children":[]},
                {"ID":820,"order":6,"parent":829,"title":"big","url":"http://awp.dev.adler.limeira.eu/animals/big/","attr":"","target":"","classes":"","xfn":"","description":"","object_id":2,"object":"animal_type","type":"taxonomy","type_label":"Animal type","children":[
                    {"ID":821,"order":7,"parent":820,"title":"long","url":"http://awp.dev.adler.limeira.eu/animals/big/long/","attr":"","target":"","classes":"","xfn":"","description":"","object_id":3,"object":"animal_type","type":"taxonomy","type_label":"Animal type","children":[
                        {"ID":822,"order":8,"parent":821,"title":"Evil","url":"http://awp.dev.adler.limeira.eu/animals/big/long/evil/","attr":"","target":"","classes":"","xfn":"","description":"","object_id":4,"object":"animal_type","type":"taxonomy","type_label":"Animal type","children":[]},{"ID":823,"order":9,"parent":821,"title":"nice","url":"http://awp.dev.adler.limeira.eu/animals/big/long/nice/","attr":"","target":"","classes":"","xfn":"","description":"","object_id":7,"object":"animal_type","type":"taxonomy","type_label":"Animal type","children":[]}
                    ]},
                    {"ID":824,"order":10,"parent":820,"title":"yellow","url":"http://awp.dev.adler.limeira.eu/animals/big/yellow/","attr":"","target":"","classes":"","xfn":"","description":"","object_id":6,"object":"animal_type","type":"taxonomy","type_label":"Animal type","children":[]}]},
                {"ID":825,"order":11,"parent":829,"title":"fat","url":"http://awp.dev.adler.limeira.eu/animals/fat/","attr":"","target":"","classes":"","xfn":"","description":"","object_id":8,"object":"animal_type","type":"taxonomy","type_label":"Animal type","children":[]}
            ]}
        ];
*/
        // $api.app.init.read({
        //     headers: { 'X-Test': 'Was Passed' },
        //     params: { test: 'ok' },
        //     data: { p1: 'test', p2: 'tst2' }
        // }).then(function(res) { console.log(res); });

        // $scope.tst1 = 'one';
        // $scope.tst2 = 'two';

        // $scope.swapTst = function() {
        //     var tmp = $scope.tst1;
        //     $scope.tst1 = $scope.tst2;
        //     $scope.tst2 = tmp;
        //     console.log(['homeRouteCtrl.swapTst', $scope.tst1, $scope.tst2]);
        // };

        // $scope.subscribe({
        //     name: 'navs',
        //     bind: 'rs',
        //     interval: 15,
        //     inputs: ['tst1', 'tst2'],
        //     on: {
        //         update: function(msg) { console.debug(['onUpdate', msg]); },
        //         error: function(msg) { console.error(['onError', msg]); },
        //         stop: function(msg) { console.info(['onStop', msg]); }
        //     }
        // });

    }

})();
