(function() {
    'use strict';

    angular
        .module('app.layout')
        .directive('pagination', pagination);

    /* @ngInject */
    function pagination($location) {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            bindToController: true,
            templateUrl: 'pagination.tpl',
            controller: paginationController,
            controllerAs: 'vm',
            link: link,
            restrict: 'E',
            scope: {
                stats: '<'
            }
        };
        return directive;
        /////////////////
        
        function link(scope, element, attrs) {

            scope.vm.pages = function() {
                if(scope.vm.stats.max_num_pages > 1) {
                    // max_num_pages 4
                    var curl = $location.absUrl().replace(/page\/\d*?\/$/, '');
                    var pages = {};
                    for (var i = 1; i <= scope.vm.stats.max_num_pages; i++) {
                        pages[i] = curl + 'page/' + i +'/';
                    }
                    return pages;
                } else {
                    return {};
                }
            };

            scope.vm.isActive = function(idx) {
                // paged 2
                return (scope.vm.stats.paged) ? (idx == scope.vm.stats.paged) : (!scope.vm.stats.paged && idx == 1);
            };

        }

    }

    /* @ngInject */
    function paginationController() {
    }

})();