(function() {
    'use strict';

    angular
        .module('app.layout')
        .directive('tax', tax);

    /* @ngInject */
    function tax() {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            bindToController: true,
            templateUrl: 'tax.tpl',
            controller: taxController,
            controllerAs: 'vm',
            link: link,
            restrict: 'E',
            scope: {
                data: '<'
            }
        };
        return directive;
        /////////////////

        function link(scope, element, attrs) {
        }

    }

    /* @ngInject */
    function taxController() {
    }

})();