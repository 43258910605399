(function() {
    'use strict';

    angular
        .module('app.dashboard')
        .run(appRun);

    /* @ngInject */
    function appRun($rootScope, $jdata, $api, routehelper) {
        console.log('appRun');
/*
        /acf/v2/post/(?P<id>[\\d]+)/?(?P<field>[\\w\\-\\_]+)?
        /acf/v2/post/238
        /acf/v2/post/238/title
        $api.v2.post([238]).read()...
        $api.v2.post([238,'title']).read()...
        $api.app.init.read().then(function(res) {
            console.log(['$api.app.init', res]);
            $rootScope.asid = res.data.session;
            $rootScope.publishes = res.data.publishes;
        });
*/
        routehelper.configureRoutes(getRoutes());
    }

    function getRoutes() {
        return [
            // {
            //     url: '/',
            //     config: {
            //         template: document.getElementById('home.tpl').innerHTML,
            //         controller: 'homeRouteCtrl',
            //         title: 'Home'
            //     }
            // },
            // {
            //     url: '/second',
            //     config: {
            //         template: document.getElementById('second.tpl').innerHTML,
            //         controller: 'secondRouteCtrl',
            //         title: 'Second'
            //     }
            // },
            // {
            //     url: '/:reqUri*',
            //     config: {
            //         template: document.getElementById('404.tpl').innerHTML,
            //         controller: 'e404RouteCtrl',
            //         title: '404 Not Found'
            //     }
            // }
            {
                url: ':reqUri*',
                config: {
                    // template: document.getElementById('route.tpl').innerHTML,
                    templateUrl: 'route.tpl',
                    controller: 'routeCtrl',
                    resolve: { json: function($jdata) { return $jdata.get(
                        window.location.href + ((window.location.href.charAt(window.location.href.length - 1) !== '/') ? '/' : '') + 'jdata/'
                    ); } }
                }
            }
        ];
    }

})();
