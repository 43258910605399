(function() {
    'use strict';

    angular
        .module('app.layout')
        .component('menu', {
            templateUrl: 'menu.tpl',
            controller: menuController,
            controllerAs: '$ctrl',
            bindings: {
                menu: '<',
            },
        })
        .run(run);

    /* @ngInject */
    function menuController() {
        var $ctrl = this;

        $ctrl.onInit = function() { };
        $ctrl.onChanges = function(changesObj) { };
        $ctrl.onDestory = function() { };

    }
    /* @ngInject */
    function run($rootScope, $http) {
        $rootScope.menu = [];
        $http.get('/wp-json/wp-api-menus/v2/menu-locations/header').then(function(rs) {
            $rootScope.menu = rs.data;
        });
    }

})();
