(function() {
    'use strict';

    angular
        .module('app.core')
        .provider('$data', $data)
        .run(run);

    function $data() {
        /* jshint validthis:true */
        this.url = {};

        this.setURL = function(url) {
            this.url = url;
        };

        /* @ngInject */
        this.$get = function($rootScope, $http, $interval) {

            var subscriptions = {};
            subscriber(subscriptions, this.url);

            return {
                url: this.url,
                subscriptions: subscriptions
            };
            /////////////

            /*
            function apiReq(req) {
                var deferred = $q.defer();

                $http({
                    url: '/api/test/it',
                    method: 'POST',
                    headers: { 'X-Test': 'Was Passed' },
                    params: { test: 'ok' },
                    data: { p1: 'test', p2: 'tst2' }
                }).then(function(response) {
                    console.log(response);
                });

                var data = { resp: {}, status: 'Initialized', count: 0};

                $httpBackend.whenGET("data.json").respond({type:'mock'});

                //just loop 10 times for an example
                var completed = $interval(function() {
                    data.status = 'Running';

                    $http.get('data.json').then(function(r) {
                        data.resp = r.data.type;
                        data.count++;
                        console.log('Http\'s:' + data.count);
                        deferred.notify(data);
                    });
                }, 2500, 10);

                completed.then(function() {
                    data.status = 'Completed!';
                    deferred.resolve(data); 
                });

                return deferred.promise;
            }
            */

            function subscriber(subscriptions, url) {

                console.log(['subscriber', this, subscriptions, url]);
                var worker = $interval(function() {

                    var currentRun = new Date().getTime();
                    angular.forEach(subscriptions, function(sdata, sid) {
                        // watches.push(scope[watchVar]);
                        if(currentRun - sdata.lastRun >= sdata.interval * 1000) {
                            console.log(['$rootScope', $rootScope]);
                            console.log(['sdata', sdata]);
                            console.log({
                                'log': 'subscriberRun',
                                'currentRun': currentRun,
                                'lastRun': sdata.lastRun,
                                'realInterval': Math.floor((currentRun - sdata.lastRun) / 100) / 10,
                                'time': new Date().toTimeString() // toString()
                            });
                            $http({
                                url: url + '/' + sdata.args.name,
                                method: 'POST',
                                headers: {
                                    'ASID': $rootScope.asid,
                                    'NPH': 1
                                },
                                data: sdata.data
                            }).then(function(response) {
                                sdata.scope[sdata.args.bind] = response.data;
                                console.info(['good', response]);
                            }, function(response) {
                                console.error(['bad', response]);
                            });
                            if(sdata.lastRun === 0) {
                                // sdata.name
                                // sdata.data
                            }
                            sdata.lastRun = currentRun;
                        }
                    });
                    // $http.get('data.json').then(function(r) {
                    //     data.resp = r.data.type;
                    //     data.count++;
                    //     console.log('Http\'s:' + data.count);
                    //     deferred.notify(data);
                    // });

                }, 1000);

                worker.then(function() {
                    // data.status = 'Completed!';
                    // deferred.resolve(data); 
                });

            }

        };

    }

    /* @ngInject */
    function run($rootScope, $data) {

        $rootScope.easyDoesIt = function() {
            // var args = Array.prototype.slice.call(arguments);
            // console.log(['app.core.run.easyDoesIt', this, args]);
            this.rs = 'sure it does';
        };

        Object.getPrototypeOf($rootScope).api = $data.api;

        Object.getPrototypeOf($rootScope).subscribe = function () {

            var args = Array.prototype.slice.call(arguments)[0];
            var ssid = new Date().getTime(); var scope = this;
            $data.subscriptions[ssid] = {};
            $data.subscriptions[ssid].args = args || {};
            $data.subscriptions[ssid].scope = scope;
            $data.subscriptions[ssid].interval = args.interval || 1;
            $data.subscriptions[ssid].lastRun = 0;

            if(args.inputs && angular.isArray(args.inputs)) {
                var inputs = function() { var watches = [];
                    angular.forEach(args.inputs, function(watchVar) { watches.push(scope[watchVar]); }); return watches;
                };
                $data.subscriptions[ssid].inputs = inputs;
                // console.log(['inputs before watch ' + ssid, inputs]);
                $rootScope.$watch(inputs, function(nVal, oVal) {
                    $data.subscriptions[ssid].lastRun = 0;
                    $data.subscriptions[ssid].data = nVal;
                    console.log(['inputs watch for ' + ssid, nVal, oVal]);
                }, true);
            }

            // console.log(['app.core.run.subscribe', this, args, arguments, ssid]);

            this.$on('$destroy', function () {
                delete $data.subscriptions[ssid];
                // console.log(['$on.$destroy', ssid, $data.subscriptions]);
            });

        };

    }

})();

// myAppModule.controller('MyController', function($scope, $data) {

//     $data.then(
//         function(value) {
//             //fully resolved (successCallback)
//             $scope.data = value;
//             console.log('Success Called!');
//         },
//         function(reason) {
//             //error (errorCallback)
//             console.log('Error:' + reason);
//         },
//         function(value) {
//             //notify (notifyCallback)
//             $scope.data = value;
//             console.log('Notify Calls:' + value.count);
//         }
//     );

// });
