(function() {
    'use strict';

    angular
        .module('app.layout')
        .directive('home', home);

    /* @ngInject */
    function home() {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            bindToController: true,
            templateUrl: 'home.tpl',
            controller: homeController,
            controllerAs: 'vm',
            link: link,
            restrict: 'E',
            scope: {
                data: '<'
            }
        };
        return directive;
        /////////////////
        
        function link(scope, element, attrs) {
        }

    }

    /* @ngInject */
    function homeController() {
    }

})();