(function() {
'use strict';

    angular
        .module('app.core')
        .factory('$jdata', $jdata);

    /* @ngInject */
    function $jdata($q, $http) {
        var jdata = {
            get: get
        };

        return jdata;
        /////////////

        function get(url) {
            var deferred = $q.defer();
            $http.get(url).then(
                function(response) { // console.log(['resolve', response]);
                    deferred.resolve(response);
                },
                function(response) { // console.log(['reject', response]);
                    deferred.resolve(response);
                }
            );
            return deferred.promise;
        }
    }

})();