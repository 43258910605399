(function() {
    'use strict';

    var core = angular.module('app.core');

    var config = {
        appErrorPrefix: '[SilkRoad Error] ', // Configure the exceptionHandler decorator
        appTitle: 'SilkRoad App Framework',
        version: '1.0.0'
    };

    core.value('config', config);

    core.config(configure);

    /* @ngInject */
    function configure(
        $logProvider, $locationProvider, $httpProvider, $routeProvider, $apiProvider, $dataProvider,
        routehelperConfigProvider, exceptionHandlerProvider
    ) {

        // turn debugging off/on (no info or warn)
        if($logProvider.debugEnabled) {
            $logProvider.debugEnabled(true);
        }

        $apiProvider.configure(apidata);
        // $apiProvider.configure({
        //     '/api/app/init' : 'r',
        //     '/api/test/it' : 'rud',
        //     '/api/tst2/at' : 'rud'
        // });

        $dataProvider.setURL('/api/publish');

        // Configure the common route provider
        routehelperConfigProvider.config.$routeProvider = $routeProvider;
        routehelperConfigProvider.config.docTitle = 'SilkRoad: ';
        var resolveAlways = { /* @ngInject */
            ready: function(dataservice) {
                return dataservice.ready();
            }
        };
        routehelperConfigProvider.config.resolveAlways = resolveAlways;

        // Configure the common exception handler
        exceptionHandlerProvider.configure(config.appErrorPrefix);

        $locationProvider.html5Mode({ enabled: true, requireBase: false });

        var param = function(obj) {
            var query = '', name, value, fullSubName, subName, subValue, innerObj, i;
            for(name in obj) { value = obj[name];
                if(value instanceof Array) { for(i=0; i<value.length; ++i) {
                    subValue = value[i]; fullSubName = name + '[' + i + ']';
                    innerObj = {}; innerObj[fullSubName] = subValue; query += param(innerObj) + '&';
                }} else if(value instanceof Object) { for(subName in value) {
                    subValue = value[subName]; fullSubName = name + '[' + subName + ']';
                    innerObj = {}; innerObj[fullSubName] = subValue; query += param(innerObj) + '&';
                }} else if(value !== undefined && value !== null)
                    query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
            }
            return query.length ? query.substr(0, query.length - 1) : query;
        };
        $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
        $httpProvider.defaults.transformRequest = [function(data) {
            return angular.isObject(data) && String(data) !== '[object File]' ? param(data) : data;
        }];

    }

})();
